import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { ReactComponent as UploadIcon } from '@/assets/images/new-icons/upload-icon-2.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
import { color } from '@/assets/plugins/raphael/raphael';

const { Dragger } = Upload;

const CustomUpload = ({
  fileList,
  setFileList,
  condition,
}: {
  fileList: UploadFile[];
  setFileList: (value: UploadFile[]) => void;
  condition: any;
}) => {
  const [hoveredFile, setHoveredFile] = useState<any>(null);
  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    // newFileList = newFileList.slice(-5);
    // setFileList(newFileList);

    newFileList.forEach((file) => {
      if (file.status === 'uploading') {
        setTimeout(() => {
          file.status = 'done';
          setFileList([...newFileList]);
        }, 1000);
      }
    });
  };

  const handleRemove = (file: UploadFile) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    message.info(`${file.name} has been removed.`);
  };

  return (
    <div id="custom-upload-azure">
      <Dragger
        id="custom-upload"
        maxCount={5}
        name="files"
        multiple
        fileList={fileList}
        onRemove={handleRemove}
        onChange={handleChange}
        customRequest={() => {}}
        showUploadList={false}
        // iconRender={(file) => (
        //   <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        //     <IconAttachment />
        //     <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{file.name}</span>
        //     <IconChecked style={{ color: 'green', marginLeft: '0.5rem' }} />
        //   </div>
        // )}
      >
        <UploadIcon style={{ width: '50px' }} />

        {condition === 'create' && (
          <div className="align-items-center" style={{}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <p className="ant-upload-text" style={{ color: '#8A8988', fontSize: '14px', lineHeight: '20px', fontWeight: 500 }}>
                Drag and drop your file here or
              </p>
              <p
                style={{
                  color: '#1E1D1D',
                  borderRadius: '6px',
                  border: '1px solid #E4E3E2',
                  padding: '6px 16px',
                  gap: '10px',
                  width: 'fit-content',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  backgroundColor: 'white',
                }}
              >
                Click here
              </p>
            </div>
          </div>
        )}
      </Dragger>
      {fileList.length > 0 && (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {fileList.map((file) => (
            <li
              key={file.uid}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5rem 0',
                borderBottom: '1px solid #eee',
                position: 'relative',
              }}
              onMouseEnter={() => setHoveredFile(file.uid)} // Khi hover, set file vào trạng thái hovered
              onMouseLeave={() => setHoveredFile(null)} // Khi rời khỏi, reset trạng thái hovered
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconAttachment />
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{file.name}</span>
              </div>

              {hoveredFile === file.uid && (
                <DeleteOutlined
                  onClick={() => handleRemove(file)}
                  style={{
                    color: 'grey',

                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                />
              )}
              {hoveredFile === null && <IconChecked />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomUpload;
