
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { Card, Col, Row, Tag } from 'antd';
const PositionInfoDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title d-flex align-items-center">
            <span className="spot-primary"></span>Position Info
          </p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Country of Hire</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.countryOfHire?.name ? (
            <div>
              <img
                src={detail?.countryOfHire?.flag}
                width="16"
                height="12"
                alt={`${detail?.countryOfHire?.name}`}
                className="mr-1"
              />
              {detail?.countryOfHire?.name}
            </div>
          ) : (
            <p className="none">none</p>
          )}
        </Col>
        <Col xs={8}>
          <p className="label">Job Title</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.jobTitle || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Work Type</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.workType ? matchValueToLabel(constants?.workTypes || [], detail?.workType) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Job Description</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.jobDescription || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">End Client</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.endClient?.name || <p className="none">none</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default PositionInfoDetail;
