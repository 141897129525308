
import { currencyFormat } from '@/common/constants/format';
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { Card, Col, Row, Tag } from 'antd';
const PaymentDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24}>
          <p className="title d-flex align-items-center">
            <span className="spot-primary"></span>Payment
          </p>
        </Col>
        <Col xs={24}>
          <hr className="my-2" />
        </Col>
        <Col xs={8}>
          <p className="label">Payroll Type</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.payrollType ? matchValueToLabel(constants?.payrollTypes || [], detail?.payrollType) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Currency</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.currency || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Pay Rate</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.payRate ? detail?.payRate : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Pay Frequency</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.payFrequency ? matchValueToLabel(constants?.payFrequencies || [], detail?.payFrequency) : <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Bonus</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.bonuses || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Allowances</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.allowances || <p className="none">none</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentDetail;
