import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IUser } from '@/types/app';
import { Button, Card, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Space, Spin, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import { constantsToOptions, matchValueToLabel } from '@/utils';
import { profileActions } from '@/stores/profile';
import { dateTimeFormat } from '@/common/constants/format';
import dayjs from 'dayjs';
import { ArrowLeftIcon, DeleteIcon, EditIcon } from '../common/IconComponents';
import { UserRole } from '@/common/constants/enums';
const UserManagementForm = (props: { create?: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { constants } = useSelector((state) => state.app);
  const { user, loading } = useSelector((state) => state.profile);

  const { detail } = useSelector((state) => state.user);
  const { userInfo: userInfoStore } = useSelector((state) => state.auth);

  const role = Form.useWatch('role', form);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(userActions.getUser({ id }));
    } else if (!props.create) {
      if (user) {
        form.setFieldsValue(user);
      }
    }
  }, [id, user]);

  useEffect(() => {
    if (!props.create) {
      if (detail) {
        form.setFieldsValue(detail);
      }
    } else form.resetFields();
  }, [detail]);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-fluid">
        <Spin tip="Loading" size="large" spinning={loading[profileActions.getMe.typePrefix] || false}>
          <>
            <div className="d-flex justify-content-between align-items-center py-3">
              <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
                <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
                <span>Profile</span>
              </div>

              <Space size="small" className="d-flex align-items-center">
                {userInfoStore?.role === 'admin' ? (
                  <Button ghost type="primary" role="button" className="d-flex align-items-center" onClick={() => setIsModalOpen(true)}>
                    Change Password
                  </Button>
                ) : null}
                {userInfoStore?.role !== 'employee' ? (
                  <Link to="./update">
                    <Button type="primary" htmlType="submit" className="d-flex align-items-center">
                      <EditIcon /> Edit
                    </Button>
                  </Link>
                ) : null}
              </Space>
            </div>
          </>
          {/* ) : (
            <div className="d-flex p-4"></div>
          )} */}
          <Card className="general-information mb-3">
            <p className="title border-bottom pb-3">{user?.name || user?.employee?.name}</p>
            <Row gutter={[20, 20]} className="pt-3">
              <Col xs={4}>
                <p className="label">Username</p>
              </Col>
              <Col xs={20} className="value">
                {user?.username ? (
                  '@' + user?.username
                ) : user?.employee?.username ? (
                  '@' + user?.employee?.username
                ) : (
                  <p className="none">none</p>
                )}
              </Col>
              <Col xs={4}>
                <p className="label">Email</p>
              </Col>
              <Col xs={20} className="value">
                {user?.email || user?.employee?.email || <p className="none">none</p>}
              </Col>
              <Col xs={4}>
                <p className="label">Phone Number</p>
              </Col>
              <Col xs={20} className="value">
                {user?.phoneNumber || user?.employee?.phoneNumber || <p className="none">none</p>}
              </Col>
              <Col xs={4}>
                <p className="label">Address</p>
              </Col>
              <Col xs={20} className="value">
                {user?.address || user?.employee?.address || <p className="none">none</p>}
              </Col>
              <Col xs={4}>
                <p className="label">Role</p>
              </Col>
              <Col xs={20} className="value text-capitalize">
                <Tag
                  color={user?.role === UserRole.admin ? 'purple' : user?.role === UserRole.company ? 'magenta' : 'blue'}
                  className="text-uppercase"
                >
                  {user?.role}
                </Tag>
              </Col>
              {userInfoStore?.role !== 'employee' ? (
                <>
                  <Col xs={4}>
                    <p className="label">Department</p>
                  </Col>
                  <Col xs={20} className="value text-capitalize">
                    {user?.department ? (
                      <Tag>{matchValueToLabel(constants?.departments || [], user?.department || '')}</Tag>
                    ) : (
                      <p className="none">none</p>
                    )}
                  </Col>
                </>
              ) : null}
              {user?.role !== 'admin' ? (
                <>
                  <Col xs={4}>
                    <p className="label">Country</p>
                  </Col>
                  <Col xs={20} className="value text-capitalize">
                    {user?.country?.id ? (
                      <Space>
                        <img src={user?.country?.flag} width="16" height="12" alt={`${user?.country?.name}`} className="mr-2" />
                        {user?.country?.name}
                      </Space>
                    ) : (
                      <p className="none">none</p>
                    )}
                  </Col>
                </>
              ) : null}
              <Col xs={4}>
                <p className="label">Created At</p>
              </Col>
              <Col xs={20} className="value text-capitalize">
                {user?.createdAt ? dayjs(user?.createdAt).format(dateTimeFormat) : <p className="none">none</p>}
              </Col>
            </Row>
          </Card>
          <Card>
            <div className="d-flex" style={{ height: '155px' }}>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="mr-2">
                  <h4>2FA Authenticator App</h4>
                  <p className="mb-0">Two-step verification services using the Time-based One-time Password Algorithm</p>
                </div>
                {user?.isEnabledTwoFactorAuthentication ? (
                  <Popconfirm
                    zIndex={9999}
                    title="Disable 2FA Authenticator App"
                    description={`Are you sure to disable?`}
                    onConfirm={async function (e) {
                      e?.stopPropagation();
                      dispatch(profileActions.disableAuthenticator(user.id))
                        .unwrap()
                        .then(() => {
                          dispatch(profileActions.getMe());
                        })
                        .catch((err) => console.log);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" disabled={loading[profileActions.disableAuthenticator.typePrefix]}>
                      Disable
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type="primary"
                    onClick={async () => {
                      await dispatch(profileActions.generateQRCode({}));
                      await dispatch(profileActions.setIsAuthenticatorVisible(true));
                    }}
                  >
                    Enable
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </Spin>
      </div>
      <ChangePasswordModal isOpen={isModalOpen} setIsOpen={(e: boolean) => setIsModalOpen(e)} />
    </>
  );
};

export default UserManagementForm;
