import Areachart from '@/components/common/areachart';
import Candlestick from '@/components/common/candlestick';
import Columnchart from '@/components/common/columnchart';
import Donutchart from '@/components/common/donutchart';
import Piechart from '@/components/common/piechart';
import { useDispatch, useSelector } from '@/stores';
import { dateFormat, dateFormat2, pageSizeOptions } from '@/common/constants/format';
import { dashboardActions } from '@/stores/dashboard';
import Paragraph from 'antd/es/typography/Paragraph';
import { useEffect, useState } from 'react';
import ListStaticsEmployees from './components/ListStaticsEmployees';
import ChartEmployees from './components/ChartEmployees';
import ListTopEmployees from './components/ListTopEmployees';
import { Button, Card, Col, Row, Space, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ListStaticsOffBoardingEmployees from './components/ListStaticsOffBoardingEmployees';
import ListEmployeesByEachCountry from './components/ListEmployeesByEachCountry';
import { BriefCaseIcon, GlobeIcon, PlusIcon, Users3Icon } from '@/components/common/IconComponents';
import DashboardStatistics from './components/DashboardStatistics';
import DashboardOnboarding from './components/DashboardOnboarding';
import DashboardOffBoarding from './components/DashboardOffBoarding';
import DashboardAzureGlobalInsight from './components/DashboardAzureGlobalInsight';
import dayjs from 'dayjs';
import { ReactComponent as BackIcon } from '@/assets/images/new-icons/back-icon.svg';
import { ReactComponent as FileIcon } from '@/assets/images/new-icons/file-icon.svg';
import { ReactComponent as EditIcon } from '@/assets/images/new-icons/edit.svg';
import { IInsight } from '@/types/app';
import { insightActions } from '@/stores/insight';
import CreateInsightModal from '@/components/Modals/CreateInsightModal';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [showSubAzureGlobal, setShowSubAzureGlobal] = useState<boolean>(false);
  const [subDataAzure, setSubDataAzure] = useState<any>(null);
  const [isCreateInsightModalOpen, setIsCreateInsightModalOpen] = useState<boolean>(false);
  const { staticsEmployees, summaryEmployees, topEmployees, staticsOffBoardingEmployees } = useSelector((state) => state.dashboard);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const { constants, countries } = useSelector((state) => state.app);

  const handleClickCreateAzure = () => {
    setIsCreateInsightModalOpen(true);
  };

  const handleClickBack = () => {
    setShowSubAzureGlobal(false);
    setSubDataAzure(null);
  };

  const handleSubmitDocument = (values: IInsight) => {
    try {
      setDocumentLoading(true);
      let newValues: IInsight = { ...values };
      if (!subDataAzure) {
        const nextPage = 1;
        setCurrentPage(nextPage);
        dispatch(insightActions.createInsight(newValues))
          .unwrap()
          .then(() => {
            dispatch(
              insightActions.getInsights({
                page: nextPage,
                limit: pageSize,
              }),
            );
            setDocumentLoading(false);
            setIsCreateInsightModalOpen(false);
          });
        // .catch(() => {
        //   setDocumentLoading(false);
        //   setIsCreateInsightModalOpen(false);
        // });
      } else {
        if (subDataAzure?.id) {
          dispatch(insightActions.updateInsight({ ...newValues, id: subDataAzure.id }))
            .unwrap()
            .then((response: any) => {
              dispatch(
                insightActions.getInsights({
                  page: currentPage,
                  limit: pageSize,
                }),
              );
              setDocumentLoading(false);
              setIsCreateInsightModalOpen(false);
              setSubDataAzure(response?.data?.data || subDataAzure);
            });
          // .catch(() => {
          //   setDocumentLoading(false);
          //   setIsCreateInsightModalOpen(false);
          // });
        }
      }
    } catch (err) {
      console.error(err);
      setDocumentLoading(false);
      setIsCreateInsightModalOpen(false);
    }
  };

  useEffect(() => {
    // dispatch(dashboardActions.getDashboardSummaryEmployees());
    // dispatch(dashboardActions.getDashboardTopEmployees());
    dispatch(dashboardActions.getDashboardOnboardingEmployees());
    dispatch(dashboardActions.getDashboardOffBoardingEmployees());
  }, []);

  useEffect(() => {
    console.log('Show is', showSubAzureGlobal);
    console.log('Data is', subDataAzure);
  }, [showSubAzureGlobal, subDataAzure]);

  return (
    <>
      <div className="dashboard-container container-fluid pt-4">
        {showSubAzureGlobal === false && <p className="page-listing-title">Dashboard</p>}
        {showSubAzureGlobal === false && (
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={10}>
              <Card className="dashboard-statistics bg-white">
                <DashboardStatistics />
              </Card>
            </Col>
            <Col xs={24} lg={7}>
              <Card className="dashboard-onboarding bg-white">
                <DashboardOnboarding />
              </Card>
            </Col>
            <Col xs={24} lg={7}>
              <Card className="dashboard-onboarding bg-white">
                <DashboardOffBoarding />
              </Card>
            </Col>
            <Col xs={24} lg={24}>
              <Card className="dashboard-azure-global-insights bg-white">
                <div className="d-flex align-items-center">
                  <p className="title">Azure Global Insights</p>
                  <Button type="primary" onClick={handleClickCreateAzure} className="ml-auto d-flex align-items-center">
                    <PlusOutlined className="mr-2" />
                    Create Insight
                  </Button>
                </div>

                <DashboardAzureGlobalInsight
                  show={showSubAzureGlobal}
                  setIsShow={setShowSubAzureGlobal}
                  details={subDataAzure}
                  setIdDetails={setSubDataAzure}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </Card>
            </Col>
          </Row>
        )}

        {showSubAzureGlobal && (
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center cursor-pointer" onClick={handleClickBack}>
                <BackIcon style={{ marginRight: '12px', cursor: 'pointer' }} />
                <span style={{ color: '#1E1D1D' }}>Back</span>
              </div>
              <Button
                type="primary"
                onClick={handleClickCreateAzure}
                className="ml-auto d-flex align-items-center"
                style={{ textAlign: 'center', gap: 10 }}
              >
                <EditIcon />
                Edit
              </Button>
            </div>

            <Paragraph
              style={{
                color: '#000000',
                fontSize: '24px',
                lineHeight: '32px',
                fontWeight: 700,
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                width: '100%',
              }}
            >
              {subDataAzure?.title}
            </Paragraph>
            <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 500, color: '#000000' }}>
              {dayjs(subDataAzure?.createdAt || 0).format(dateFormat2)}
            </div>

            <div className="" style={{ marginTop: '20px' }}>
              <div
                style={{
                  border: '1px solid #FFFFFF',
                  backgroundColor: '#FFFFFF',
                  color: '#000000',
                  padding: '24px 20px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 400,
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-line',
                  width: '100%',
                  borderRadius: 8,
                }}
              >
                {subDataAzure?.content}
              </div>
            </div>

            <div className="mt-4 d-flex" style={{ gap: '20px' }}>
              {/* <div>Attachments: </div> */}
              {subDataAzure.attachments.map((attachment: any, index: any) => (
                <div key={index}>
                  {/* <p>File Name: {attachment.metadata.name}</p> */}
                  {/* <p>File Size: {attachment.metadata.size} bytes</p> */}
                  {/* <p>Content Type: {attachment.contentType}</p> */}
                  {attachment.fileURL && (
                    <div className="d-flex" style={{ width: 'fit-content' }}>
                      <a
                        className="d-flex"
                        href={attachment.fileURL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'inline-block' }}
                      >
                        {/* <img src={attachment.fileURL} alt="Attachment" style={{ maxWidth: '25%', height: 'auto', cursor: 'pointer' }} /> */}
                        <span
                          className="d-flex mt-2"
                          style={{ border: '1px solid #E4E3E2', backgroundColor: '#FFFFFF', padding: '8px 12px', borderRadius: '8px' }}
                        >
                          <FileIcon />
                          <span
                            style={{
                              display: 'inline-block',
                              maxWidth: '150px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {attachment.metadata.name}
                          </span>
                        </span>
                        {/* <p>File Size: {attachment.metadata.size} bytes</p> */}
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {isCreateInsightModalOpen && (
          <CreateInsightModal
            open={isCreateInsightModalOpen}
            destroyOnClose={true}
            onCancel={() => setIsCreateInsightModalOpen(false)}
            onSubmit={handleSubmitDocument}
            hideCountryCode={true}
            hideDocumentType={true}
            confirmLoading={documentLoading}
            data={subDataAzure}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
