import api from '@/common/api';
import { UserRole } from '@/common/constants/enums';
import { ROUTE_PATH } from '@/common/constants/routes';
import { SLICE_NAME } from '@/common/constants/stores';
import { ILoginForm, ILoginResponse, IRegisterForm, IRegisterResponse, IUser } from '@/types/app';
import { openNotification } from '@/utils';
import {
  getToken,
  getRefreshToken,
  getUserInfo,
  getRootProfile,
  getRootRefreshToken,
  getRootToken,
  isTokenValid,
  navigateTo,
  setRefreshToken,
  setToken,
  setUserInfo,
  setRootProfile,
  setRootRefreshToken,
  setRootToken,
} from '@/utils/auth';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface IAuthState {
  loading: { [x: string]: boolean };
  isAuthenticated: boolean;
  userInfo?: IUser;
  token?: string;
  refreshToken?: string;
}

export const initialState: IAuthState = {
  loading: {},
  isAuthenticated: isTokenValid(getToken()),
  userInfo: getUserInfo() || undefined,
  token: getToken(),
  refreshToken: getRefreshToken(),
};

const login = createAsyncThunk(`${SLICE_NAME.AUTH}/loginEmail`, async ({isEmployee ,...form}:ILoginForm) => {
  const response = isEmployee ?
    await api.loginByUsername<ILoginResponse>({
      ...form,
    })
    :await api.login<ILoginResponse>({
      ...form,
    });
  return response;
});


const signup = createAsyncThunk(`${SLICE_NAME.AUTH}/registerEmail`, async (form: IRegisterForm, { rejectWithValue }) => {
  try {
    return api.createUser<IRegisterResponse>(
      {
        ...form,
      },
      { params: { id: 1 } },
    );
  } catch (err) {
    return rejectWithValue(err);
  }
});

const loginAsUser = createAsyncThunk(`${SLICE_NAME.AUTH}/loginAsUser`, async (userId: string | undefined, { rejectWithValue }) => {
  try {
    const response = await api.loginAsUser<ILoginResponse>({ id: userId });
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const loginAsClientUser = createAsyncThunk(
  `${SLICE_NAME.AUTH}/loginAsClientUser`,
  async (userId: string | undefined, { rejectWithValue }) => {
    try {
      const response = await api.loginAsClientUser<ILoginResponse>({
        id: userId,
      });
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const resetPassword = createAsyncThunk(`${SLICE_NAME.AUTH}/resetPassword`, async (form: any | undefined, { rejectWithValue }) => {
  try {
    const response = await api.resetPassword<ILoginResponse>(form);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const forgotPassword = createAsyncThunk(`${SLICE_NAME.AUTH}/forgotPassword`, async ({isEmployee,...form}: any | undefined, { rejectWithValue }) => {
  try {
    const response = isEmployee?
      await api.forgotPasswordByUsername<ILoginResponse>(form)
      :await api.forgotPassword<ILoginResponse>(form);
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const verifyAuthenticator = createAsyncThunk(`${SLICE_NAME.AUTH}/verifyAuthenticator`, async (form: any | undefined, { rejectWithValue }) => {
  try {
    const response = await api.verifyAuthenticator<ILoginResponse>(form, {
      headers: {
        ...(form?.token ? { Authorization: 'Bearer ' + form.token } : {}),
      },
    });
    return response;
  } catch (e) {
    return rejectWithValue(e);
  }
}); 

const authSlice = createSlice({
  name: SLICE_NAME.AUTH,
  initialState: initialState,
  reducers: {
    onLogout: (state) => {
      localStorage.clear();
      state.token = undefined;
      state.refreshToken = undefined;
      state.isAuthenticated = false;
      navigateTo(ROUTE_PATH.LOGIN);
    },
    saveToken: (state, { payload }) => {
      state.isAuthenticated = isTokenValid(payload.data.data.accessToken);
      state.token = payload.data.data.accessToken;
      setToken(payload.data.data.accessToken);

      state.refreshToken = payload.data.data.refreshToken;
      setRefreshToken(payload.data.data.refreshToken);

      state.userInfo = payload.data.data.user;
      setUserInfo(payload.data.data.user);
    },
    backRootAccount: (state) => {
      const accessToken = getRootToken();
      const refreshToken = getRootRefreshToken();
      const profile = getRootProfile();

      state.isAuthenticated = isTokenValid(accessToken);
      state.token = accessToken;

      setToken(accessToken);

      state.refreshToken = refreshToken;
      setRefreshToken(refreshToken);

      state.userInfo = profile;
      setUserInfo(profile);
      navigateTo(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
    },

  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, { payload }) => {
      state.loading[login.typePrefix] = true;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.loading[login.typePrefix] = false;
      state.isAuthenticated = false;
      // openNotification('error', 'Login failed!');
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.loading[login.typePrefix] = false;
      if(!payload.data.data.user.isEnabledTwoFactorAuthentication){
        state.isAuthenticated = isTokenValid(payload.data.data.accessToken);
        state.token = payload.data.data.accessToken;
        setToken(payload.data.data.accessToken);
  
        state.refreshToken = payload.data.data.refreshToken;
        setRefreshToken(payload.data.data.refreshToken);
  
        state.userInfo = payload.data.data.user;
        setUserInfo(payload.data.data.user);
        if (payload.data.data.user.role === UserRole.admin) {
          setRootToken(payload.data.data.accessToken);
          setRootRefreshToken(payload.data.data.refreshToken);
          setRootProfile(payload.data.data.user);
          navigateTo(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
        } else if (payload.data.data.user.role === UserRole.employee) navigateTo(ROUTE_PATH.EMPLOYEE_PORTAL);
        else navigateTo(ROUTE_PATH.AUTHENTICATED);
        openNotification('success', 'Login successfully!');
      }
    });

    builder.addCase(signup.pending, (state, { payload }) => {
      state.loading[signup.typePrefix] = true;
    });
    builder.addCase(signup.rejected, (state, { payload }) => {
      state.loading[signup.typePrefix] = false;
      openNotification('error', 'Signup failed!');
    });
    builder.addCase(signup.fulfilled, (state, { payload }) => {
      state.loading[signup.typePrefix] = false;
      openNotification('success', 'Signup successfully!');
    });
    builder.addCase(loginAsUser.fulfilled, (state, { payload }) => {
      state.loading[loginAsUser.typePrefix] = false;
      state.loading[login.typePrefix] = false;
      state.isAuthenticated = isTokenValid(payload.data.data.accessToken);
      state.token = payload.data.data.accessToken;
      setToken(payload.data.data.accessToken);

      state.refreshToken = payload.data.data.refreshToken;
      setRefreshToken(payload.data.data.refreshToken);

      state.userInfo = payload.data.data.user;
      setUserInfo(payload.data.data.user);
      if (payload.data.data.user.role === UserRole.admin) navigateTo(ROUTE_PATH.USER_MANAGEMENT);
      else if (payload.data.data.user.role === UserRole.employee) navigateTo(ROUTE_PATH.EMPLOYEE_PORTAL);
      else navigateTo(ROUTE_PATH.AUTHENTICATED);
      openNotification('success', 'Login successfully!');
    });
    builder.addCase(loginAsUser.pending, (state, { payload }) => {
      state.loading[loginAsUser.typePrefix] = true;
    });
    builder.addCase(loginAsUser.rejected, (state, { payload }) => {
      openNotification('error', 'Login failed!');
      state.loading[loginAsUser.typePrefix] = false;
    });

    builder.addCase(loginAsClientUser.fulfilled, (state, { payload }) => {
      state.loading[loginAsClientUser.typePrefix] = false;
      state.loading[login.typePrefix] = false;
      state.isAuthenticated = isTokenValid(payload.data.data.accessToken);
      state.token = payload.data.data.accessToken;
      setToken(payload.data.data.accessToken);

      state.refreshToken = payload.data.data.refreshToken;
      setRefreshToken(payload.data.data.refreshToken);

      state.userInfo = payload.data.data.user;
      setUserInfo(payload.data.data.user);
      if (payload.data.data.user.role === UserRole.admin) navigateTo(ROUTE_PATH.USER_MANAGEMENT);
      else navigateTo(ROUTE_PATH.AUTHENTICATED);
      openNotification('success', 'Login successfully!');
    });
    builder.addCase(loginAsClientUser.pending, (state, { payload }) => {
      state.loading[loginAsClientUser.typePrefix] = true;
    });
    builder.addCase(loginAsClientUser.rejected, (state, { payload }) => {
      openNotification('error', 'Login failed!');
      state.loading[loginAsClientUser.typePrefix] = false;
    });

    builder.addCase(resetPassword.pending, (state, { payload }) => {
      state.loading[resetPassword.typePrefix] = true;
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.loading[resetPassword.typePrefix] = false;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.loading[resetPassword.typePrefix] = false;
      openNotification('success', 'Reset password successfully!');
    });

    builder.addCase(forgotPassword.pending, (state, { payload }) => {
      state.loading[forgotPassword.typePrefix] = true;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.loading[forgotPassword.typePrefix] = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.loading[forgotPassword.typePrefix] = false;
      openNotification('success', 'An email to forget password has just sent!');
    });
    builder.addCase(verifyAuthenticator.pending, (state, { payload }) => {
      state.loading[verifyAuthenticator.typePrefix] = true;
    });
    builder.addCase(verifyAuthenticator.rejected, (state, { payload }) => {
      state.loading[verifyAuthenticator.typePrefix] = false;
      // openNotification('error', 'Verify authenticator failed');
    });
    builder.addCase(verifyAuthenticator.fulfilled, (state, { payload }) => {
      state.loading[verifyAuthenticator.typePrefix] = false;
      state.isAuthenticated = isTokenValid(payload.data.data.accessToken);
        state.token = payload.data.data.accessToken;
        setToken(payload.data.data.accessToken);
  
        state.refreshToken = payload.data.data.refreshToken;
        setRefreshToken(payload.data.data.refreshToken);
  
        state.userInfo = payload.data.data.user;
        setUserInfo(payload.data.data.user);
        if (payload.data.data.user.role === UserRole.admin) {
          setRootToken(payload.data.data.accessToken);
          setRootRefreshToken(payload.data.data.refreshToken);
          setRootProfile(payload.data.data.user);
          navigateTo(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
        } else if (payload.data.data.user.role === UserRole.employee) navigateTo(ROUTE_PATH.EMPLOYEE_PORTAL);
        else navigateTo(ROUTE_PATH.AUTHENTICATED);
        openNotification('success', 'Login successfully!');

        // if (payload.data.data.user.role === UserRole.admin) {
        //   navigateTo(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
        // } else if (payload.data.data.user.role === UserRole.employee) navigateTo(ROUTE_PATH.EMPLOYEE_PORTAL);
        // else navigateTo(ROUTE_PATH.AUTHENTICATED);
        // openNotification('success', 'Login successfully!');
    });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = {
  ...authSlice.actions,
  login,
  signup,
  loginAsUser,
  loginAsClientUser,
  resetPassword,
  forgotPassword,
  verifyAuthenticator
};
