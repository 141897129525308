import { ICountryConstant, IInsight } from '@/types/app';
import { Form, Input, Modal } from 'antd';
import { useState, useEffect } from 'react';
import type { ModalProps } from 'antd';
import { useSelector } from '@/stores';
import CustomUpload from '@/pages/authenticated/Dashboard/customUpload';
import { UploadFile } from 'antd/lib/upload';
import { convertIAttachmentToUploadFile } from '@/utils';

interface IProps extends ModalProps {
  data?: IInsight | null;
  onSubmit: (data: IInsight) => void;
  countries?: ICountryConstant[];
  hideCountryCode?: boolean;
  hideDocumentType?: boolean;
  isChangeDescriptionToTitle?: boolean;
  isShowInputClient?: boolean;
  isEdit?: boolean;
}

const CreateInsightModal = (props: IProps) => {
  const { data, onSubmit, hideCountryCode, hideDocumentType, isChangeDescriptionToTitle, isShowInputClient, ...rest } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const initState = {};

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setFileList(data?.attachments?.map((attachment) => convertIAttachmentToUploadFile(attachment)) || []);
    } else {
      form.setFieldsValue(initState);
      setFileList([]);
    }
  }, [data]);

  const getSuccessFileList = () => {
    return fileList
      .filter((file) => file?.status === 'done')
      .map((file) => (file.originFileObj && file.originFileObj instanceof File ? file.originFileObj : file.url ? file : null))
      .filter((file) => file !== null);
  };

  const handleSubmitForm = (value: any) => {
    let newValue = { ...value, attachments: getSuccessFileList() };
    onSubmit(newValue);
    form.resetFields();
    setFileList([]);
  };

  return (
    <Modal
      {...rest}
      title={!data ? 'Create Azure Insight' : 'Edit Azure Insight'}
      onOk={form.submit}
      okText={!data ? 'Create' : 'Update'}
      width={600}
    >
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">
          <div className="col-lg-12">
            <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter a title!' }]}>
              <Input placeholder="Enter title here" maxLength={200} />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please enter a content!' }]}>
              <Input.TextArea rows={3} placeholder="Enter content here" />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item name="document" label="Document">
              <CustomUpload condition="create" fileList={fileList} setFileList={setFileList} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateInsightModal;
