import { ICountryConstant, IDocument } from '@/types/app';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState, DragEvent, ChangeEvent, useEffect } from 'react';
import type { InputRef, ModalProps, UploadFile } from 'antd';
import { useSelector } from '@/stores';
import { constantsToOptions } from '@/utils';
import SearchEmployees from '../SearchEmployees';
import { ReactComponent as IconUpload } from '@/assets/images/new-icons/icon-upload.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
import SearchUsers from '../SearchUsers';
import { UserRole } from '@/common/constants/enums';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import { InboxOutlined } from '@ant-design/icons';
const MAX_FILE_SIZE = 50;

interface IProps extends ModalProps {
  data?: IDocument | null;
  onSubmit: (data: IDocument) => void;
  countries?: ICountryConstant[];
  hideCountryCode?: boolean;
  hideDocumentType?: boolean;
  isChangeDescriptionToTitle?: boolean;
  isShowInputClient?: boolean;
}

const UploadDocumentModal = (props: IProps) => {
  const {
    data,
    onSubmit,
    title = 'Upload Document',
    hideCountryCode,
    hideDocumentType,
    isChangeDescriptionToTitle,
    isShowInputClient,
    ...rest
  } = props;
  const [form] = Form.useForm();
  const [fileName, setFileName] = useState('');
  const [attachmentUpload, setAttachmentUpload] = useState<File | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { constants, countries } = useSelector((state) => state?.app);
  const defaultCountryID = countries.find((item) => item?.countryCode === 'GB')?.id;

  const initState = {
    countryID: defaultCountryID,
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue(initState);
      setAttachmentUpload(null);
      setFileName('');
      setFileList([]);

    }
  }, [data]);

  const [dragActive, setDragActive] = useState(false);
  // ref


  // handle drag events
  function beforeUpload(file: RcFile) {
    const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt2M) {
      message.error(`File must smaller than ${MAX_FILE_SIZE}MB!`);
    }
    return false;
  }

  const handleChangeFileUpload = (info: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    console.log("***** test handleChangeFileUpload", info);
    if (
      info.fileList.length &&
      (info?.file?.size || 0) / 1024 / 1024 < MAX_FILE_SIZE
    )
      setFileList([info?.file]);
      form.setFieldValue('attachment', [info?.file]);
  };

  const handleRemoveFileUpload = (info: any) => {
    setFileList([]);
    form.setFieldValue('attachment', undefined);
  };


  const handleSubmitForm = (value: IDocument) => {
    let newValue = { ...value };
    if (fileList?.length > 0 && fileList?.[0] instanceof File) {
      newValue = { ...newValue, attachmentUpload: fileList?.[0] };
    }
    onSubmit(newValue);
    setFileList([]);
    form.resetFields();
  };

  return (
    <Modal {...rest} title={title} onOk={form.submit} okText="Create" width={600} centered>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">
          {/* <div className="col-lg-6">
            <Form.Item
              label="Department"
              name="department"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                className="w-100"
                placeholder="Select Client"
                options={constantsToOptions(constants?.departments || [])}
              />
            </Form.Item>
          </div> */}

          {!hideCountryCode && (
            <div className={`${hideDocumentType ? 'col-lg-12' : 'col-lg-6'}`}>
              <Form.Item
                label="Country"
                name="countryID"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Select
                  placeholder="Select country"
                  filterOption={(input: string, option: any) => {
                    return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                  }}
                  showSearch
                >
                  {countries?.map((item: any, index: number) => {
                    return (
                      <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                        <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
          {!hideDocumentType && (
            <div className={`${hideCountryCode ? 'col-lg-12' : 'col-lg-6'}`}>
              <Form.Item label="Document Type" name="type" rules={[{ required: true, message: 'This field is required!' }]}>
                <Select
                  className="w-100"
                  placeholder="Select document type"
                  options={constantsToOptions(constants?.documentTypes || [])}
                ></Select>
              </Form.Item>
            </div>
          )}
          {/* <div className="col-lg-12">
            <Form.Item name="employeeID" labelAlign="left" label="Employee">
              <SearchEmployees
                showSuggest
                // user={item?.user}
                onSelect={(value) => {
                  form.setFieldValue("employeeID", value);
                }}
              />
            </Form.Item>
          </div> */}

          {isShowInputClient && (
            <div className="col-lg-12">
              <Form.Item label="Client" name="userID" rules={[{ required: true, message: 'This field is required!' }]}>
                <SearchUsers
                  showSuggest
                  onSelect={(value) => {
                    form.setFieldValue('userID', value);
                  }}
                  roles={[UserRole.client]}
                />
              </Form.Item>
            </div>
          )}

          {!isChangeDescriptionToTitle ? (
            <div className="col-lg-12">
              <Form.Item name="description" label="Description">
                <Input.TextArea size="large" maxLength={5000} placeholder="Enter description" />
              </Form.Item>
            </div>
          ) : (
            <div className="col-lg-12">
              <Form.Item
                name="title"
                label="Document Title"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}
              >
                <Input className="w-100" placeholder="Enter title" />
              </Form.Item>
            </div>
          )}

          <div className="col-lg-12 mb-4">
          <Form.Item
              label="Document"
              name="attachment"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Dragger
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={handleChangeFileUpload}
                onRemove={handleRemoveFileUpload}
                multiple={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files. Max:{" "}
                  {MAX_FILE_SIZE}MB
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadDocumentModal;
