import Icon, { PhoneOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { forwardRef, type InputHTMLAttributes } from "react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";


function PhoneInputComponent(props: RPNInput.Props<any>) {
  return (
    <RPNInput.default
      className="d-flex rounded bg-white"
      international
      flagComponent={FlagComponent}
      countrySelectComponent={CountrySelect}
      inputComponent={PhoneInput}
      placeholder="Enter phone number"
      {...props}
    />
  );
}

const PhoneInput = forwardRef<any>(({ ...props }, ref) => {
  return (
    <input
      ref={ref}
      {...props}
    />
  );
});

PhoneInput.displayName = "PhoneInput";

type CountrySelectOption = { label: string; value: RPNInput.Country | undefined };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country | null) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({ disabled, value, onChange, options }: CountrySelectProps) => {
  const handleSelect = (newOption: any) => {
    onChange(newOption ?? null);
  };

  return (
    <div className="phone-input-country">
      {/* <Select
        
        // getOptionLabel={(option) =>
        //   `${option.label} ${option.value && `+${RPNInput.getCountryCallingCode(option.value)}`}`
        // }
        className="h-12 min-w-16"
      /> */}
      <Select
        options={options
          ?.filter((x) => x.value)
          ?.map((x) => ({ ...x, label: <div className="d-flex"><FlagComponent country={x.value!} countryName={x.value!} /><span className="ml-2">{x.label}</span><span>{`(+${RPNInput.getCountryCallingCode(x.value||"BR")})`}</span></div>, label2: <FlagComponent country={x.value!} countryName={x.value!} /> }))}
        value={value ?? null}
        onChange={handleSelect}
        dropdownStyle={{ minWidth: '300px' }}
        style={{ minWidth: '50px' }}
        tagRender={() => <FlagComponent country={value!} countryName={value!} />}
        optionLabelProp="label2"
        className="align-items-center"
        //   optionLabelProp={()=><FlagComponent
        //   country={value!}
        //   countryName={value!}
        // />}
      >
        <FlagComponent country={value} countryName={value} />
      </Select>
    </div>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];
  console.log('country, countryName', country, countryName, flags)

  return (
    <span style={{width: '28px', height: '20px'}} className="d-flex align-items-center overflow-hidden">
      {Flag ? <Flag title={countryName} /> : <PhoneOutlined size={16} />}
    </span>
  );
};

export default PhoneInputComponent;
