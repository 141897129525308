import { dateFormat, pageSizeOptions } from '@/common/constants/format';
import { EyeIcon, SearchIcon } from '@/components/common/IconComponents';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useDispatch, useSelector } from '@/stores';
import { activityLogActions } from '@/stores/activityLogs';
import { IActivityLog, IEmployee, IUser } from '@/types/app';
import { getBreakPoint } from '@/utils';
import { Button, Grid, Input, Spin, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ActivityLogDetailsModal from './id';

const { useBreakpoint } = Grid;

const ActivityLogs = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const dispatch = useDispatch();
  const { data, loading, pagination } = useSelector((state) => state.activityLog);

  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const keyword = search?.get('keyword') || '';

  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detailId, setDetailId] = useState<string>();


  useEffect(function () {
    dispatch(activityLogActions.getActivityLogs({}));
  }, []);

  const columns: ColumnsType<IActivityLog> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      align: 'center',
      width: 70,
      render: (_, record) =>
        record.employeeID && (
          // <Link to={record.employeeID}>
          <Button
            type="text"
            size="small"
            onClick={() => {
              setDetailId(record.employeeID);
              setIsModalOpen(true);
            }}
          >
            <EyeIcon />
          </Button>
          // </Link>
        ),
    },
    {
      title: 'Employee',
      dataIndex: 'employeeSnap',
      key: 'employee',
      width: 250,
      sorter: true,
      render: (employee: IEmployee) => (
        <div className="general-information-table">
          <p className="title">{employee?.name ?? 'N/A'}</p>
          <p className="">{employee?.email ? employee.email : ''}</p>
        </div>
      ),
    },
    {
      title: 'Log Details',
      dataIndex: 'details',
      key: 'details',
      width: 400,
      sorter: true,
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedByUser',
      key: 'updatedByUser',
      width: 200,
      sorter: true,
      render: (user: IUser, record) => user?.name ?? 'N/A',
    },
    {
      title: 'Last Update',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (val) => dayjs.unix(val).format(dateFormat),
    },
  ];

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(activityLogActions.getActivityLogs({ keyword }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      activityLogActions.getActivityLogs({
        page,
        limit: pageSize,
      }),
    );
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IEmployee> | SorterResult<IEmployee>[],
  ) => {
    dispatch(
      activityLogActions.getActivityLogs({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
      }),
    );
  };

  useEffect(() => {
    if (location.search.includes('keyword')) {
      setSearchText(keyword || '');
    }

    if (location.search && keyword) {
      dispatch(
        activityLogActions.getActivityLogs({
          keyword: searchText,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (detailId) {
      dispatch(activityLogActions.getActivityLog({ employeeID: detailId }));
    }
  }, [detailId]);

  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[activityLogActions.getActivityLogs.typePrefix] || false}>
        <p className="page-listing-title">Activity Logs</p>
        <div className="bg-white rounded border">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 1200 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
        <ActivityLogDetailsModal isModalOpen={isModalOpen} handleCloseModal={() => setIsModalOpen(false)} />
      </Spin>
    </div>
  );
};

export default ActivityLogs;
