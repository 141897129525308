import api from '@/common/api';
import { useDispatch, useSelector } from '@/stores';
import { employeeActions } from '@/stores/employee';
import { BankPayload, IBank } from '@/types/app';
import { openNotification } from '@/utils';
import type { ModalProps } from 'antd';
import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import DebouncedSearchSelect from '../DebouncedSearchSelect';

interface IProps extends ModalProps {
  type?: 'add' | 'edit';
  data?: Partial<IBank> | null;
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

const AddEditBankModal = (props: IProps) => {
  const { data, type, onClose, onSubmitSuccess, ...rest } = props;
  console.log('🚀 ~ file: AddEditBankModal.tsx:20 ~ AddEditBankModal ~ data:', data);

  const dispatch = useDispatch();
  const { detail: employeeDetail, loading } = useSelector((state) => state.employee);
  const { constants, countries } = useSelector((state) => state?.app);

  const [form] = Form.useForm<BankPayload>();

  const [searchingBankNames, setSearchBankNames] = useState(false);

  useEffect(() => {
    if (type === 'edit' && data) {
      form.setFieldsValue({
        bankNameID: data.bankName?.id ?? '',
        accountName: data.accountName ?? '',
        accountNumber: data.accountNumber ?? '',
        sortCode: data.sortCode ?? '',
        buildingSocietyNumber: data.buildingSocietyNumber ?? '',
        IBanReference: data.IBanReference ?? '',
        SwiftNo: data.SwiftNo ?? '',
        countryID: data.countryID ?? '',
      });
    } else {
      form.setFieldsValue({
        bankNameID: '',
        accountName: '',
        accountNumber: '',
        sortCode: '',
        buildingSocietyNumber: '',
        IBanReference: '',
        SwiftNo: '',
        countryID: '',
      });
    }
  }, [type, data]);

  const transformBeforeSubmit = (formValues: BankPayload) => {
    const bankNameValue = 'bankNameID' in formValues ? formValues.bankNameID : formValues.name;

    if (type === 'add') {
      return {
        ...formValues,
        id: employeeDetail?.id,
        ...(bankNameValue.startsWith('Add "') ? { name: bankNameValue.slice(5, -1) } : { bankNameID: bankNameValue }),
      };
    }

    return {
      ...formValues,
      id: employeeDetail?.id,
      bankId: data?.id,
      ...(bankNameValue.startsWith('Add "') ? { name: bankNameValue.slice(5, -1) } : { bankNameID: bankNameValue }),
    };
  };

  const handleSubmitForm = (formValues: BankPayload) => {
    console.log('🚀 ~ file: AddEditBankModal.tsx:58 ~ handleSubmitForm ~ formValues:', formValues);
    if (!employeeDetail?.id) return;

    const payload = transformBeforeSubmit(formValues);

    if (type === 'add') {
      dispatch(
        employeeActions.createEmployeeBank({
          ...formValues,
          id: employeeDetail?.id,
        }),
      )
        .unwrap()
        .then(() => {
          onSubmitSuccess?.();
          onClose?.();
        })
        .catch((res) => openNotification('error', res.error));
    } else if (data?.id) {
      dispatch(
        employeeActions.updateEmployeeBank({
          ...formValues,
          id: employeeDetail?.id,
          bankId: data.id,
        }),
      )
        .unwrap()
        .then(() => {
          onSubmitSuccess?.();
          onClose?.();
        })
        .catch((res) => openNotification('error', res.error));
    }
  };

  const handleCreateBankName = async (name: string) => {
    const res = await api.createBanksName<{ data: Required<IBank>['bankName'] }>({ name }).then((res) => {
      return [res.data.data].map((item) => ({
        value: `${item.id}`,
        label: `${item.name}`,
      }))[0];
    });

    return res;
  };

  return (
    <Modal
      {...rest}
      onCancel={() => onClose?.()}
      title={type === 'add' ? 'Add Bank' : 'Edit Bank'}
      onOk={form.submit}
      okText="Save"
      width={800}
      okButtonProps={{
        loading: loading[employeeActions.createEmployeeBank.typePrefix] || loading[employeeActions.updateEmployeeBank.typePrefix] || false,
      }}
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
        initialValues={{
          bankNameID: data?.bankName?.id ?? '',
          accountName: data?.accountName ?? '',
          accountNumber: data?.accountNumber ?? '',
          sortCode: data?.sortCode ?? '',
          buildingSocietyNumber: data?.buildingSocietyNumber ?? '',
          IBanReference: data?.IBanReference ?? '',
          SwiftNo: data?.SwiftNo ?? '',
          countryID: data?.countryID ?? '',
        }}
        preserve={false}
        className="group-permission-form"
      >
        <div className="row">
          <div className="col-lg-6">
            <Form.Item label="Name" name="bankNameID" rules={[{ required: true, message: 'This field is required!' }]}>
              <DebouncedSearchSelect
                creatable
                showSuggest
                placeholder="Select bank name"
                // value={form.getFieldValue('bankNameID')}
                loading={searchingBankNames}
                onChange={(newValue) => {
                  console.log("🚀 ~ file: AddEditBankModal.tsx:164 ~ AddEditBankModal ~ newValue:", newValue)
                  form.setFieldsValue({ bankNameID: Array.isArray(newValue) ? newValue[0] : newValue });
                }}
                getOptionsCallback={async (text) => {
                  setSearchBankNames(true);
                  const res = await api
                    .getBanksName<{ data: Required<IBank>['bankName'][] }>({ keyword: text })
                    .finally(() => setSearchBankNames(false));

                  return res.data.data
                    .map((item) => ({
                      value: `${item.id}`,
                      label: `${item.name}`,
                    }))
                    ?.filter((item) => item?.label);
                }}
                createOption={handleCreateBankName}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item label="Account Name" name="accountName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input placeholder="Enter account name" />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[
                { required: true, message: 'This field is required!' },
                { pattern: /^\d+$/, message: 'Sort code must contain only numbers' },
              ]}
            >
              <Input
                placeholder="Enter account number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="Sort code (6 digits)"
              name="sortCode"
              rules={[
                { required: true, message: 'This field is required!' },
                { len: 6, message: 'Sort code must be exactly 6 digits' },
                { pattern: /^\d+$/, message: 'Sort code must contain only numbers' },
              ]}
            >
              <Input
                placeholder="Enter sort code"
                maxLength={6}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="Building Society Number (up to 24 digits)"
              name="buildingSocietyNumber"
              rules={[
                { max: 24, message: 'Building Society Number cannot exceed 24 digits' },
                { pattern: /^\d+$/, message: 'Building Society Number must contain only numbers' },
              ]}
            >
              <Input
                placeholder="Enter building society number"
                maxLength={24}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="IBAN Reference (up to 30 digits)"
              name="IBanReference"
              rules={[
                { required: true, message: 'This field is required!' },
                { max: 30, message: 'IBAN Reference cannot exceed 30 digits' },
              ]}
            >
              <Input
                placeholder="Enter IBAN reference"
                maxLength={30}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="SWIFT No (up to 20 digits)"
              name="SwiftNo"
              rules={[
                { required: true, message: 'This field is required!' },
                { max: 20, message: 'SWIFT No cannot exceed 20 digits' },
              ]}
            >
              <Input
                placeholder="Enter SWIFT No"
                maxLength={20}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item label="Country" name="countryID" rules={[{ required: true, message: 'This field is required!' }]}>
              <Select
                placeholder="Select country"
                filterOption={(input: string, option: any) => {
                  return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {countries.map((item: any, index: number) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                      <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditBankModal;
